<template>
    <div class="details-container">
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">
                    {{ fields.real_estate_type.label }}:
                </span>
                <span>{{ landType }}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold"
                    >{{ $t("REAL_ESTATE.VIEW.ADDRESS") }}:</span>
                <span>{{ address }}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">{{ fields.territorial_administrative_unit_id.label }}:</span>
                <span>{{ uat }}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">{{ fields.land_area.label }}:</span>
                <span>{{ model[fields.land_area.name] }}</span>
            </div>
        </div>
        <div class="row mb-3" v-if="model[fields.cadastral_number.name]">
            <div class="col-md-5">
                <span class="d-block font-weight-bold">{{ fields.cadastral_number.label }}:</span>
                <span>{{ model[fields.cadastral_number.name] }}</span>
            </div>
        </div>
        <div class="row mb-3" v-if="showFiles">
            <div class="col-md-12 st-files-container">
                <span class="d-block font-weight-bold">{{$t('REAL_ESTATE.VIEW.FILES_LABEL')}}:</span>
                <div v-for="(doc, index) in files" :key="index" class="st-file-item col-md-4 mr-2">
                    <div class="text-truncate">{{ doc.filename || doc.name }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { RealEstateModel } from "@/modules/document-series-allocation/models/real-estate-model";
import { FormSchema } from "@/shared/form/form-schema";

const { fields } = RealEstateModel;
const formSchema = new FormSchema([
    fields.real_estate_type,
    fields.county_id,
    fields.county,
    fields.locality_id,
    fields.locality,
    fields.village_id,
    fields.village,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.land_area,
    fields.other_identification,
    fields.cadastral_number,
    fields.territorial_administrative_unit_id
]);

export default {
    name: "RealEstateDetails",
    props: {
        item: {
            type: Object,
        },
    },
    data() {
        return {
            fields,
        };
    },
    computed: {
        address() {
            let address = this.item.county;
            if (this.item.real_estate_type === "land_constructions") {
                address += `, ${this.item.locality} ${this.item.street} ${this.item.street_number}`;
            }

            return address;
        },
        landType() {
            return fields.real_estate_type.options.find(
                (land) =>
                    this.model[fields.real_estate_type.name] === land.value
            ).text;
        },
        uat() {
            return fields.territorial_administrative_unit_id.options.find(
                (uat) =>
                    this.model[fields.territorial_administrative_unit_id.name] === uat.value
            ).text;
        },
        model() {
            return formSchema.initialValues(this.item);
        },
        showFiles() {
            return this.files?.length;
        },
        files() {
            return this.item?.files;
        }
    },
    methods: {},
    created() {

    },
};
</script>
