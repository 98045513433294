import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EmailField from '@/shared/fields/email-field';
import { LocalityField } from '@/shared/common-fields/locality-field';
import { CountyField } from '@/shared/common-fields/county-field';
import { VillageField } from '@/shared/common-fields/village-field';
import { CountryField } from '@/shared/common-fields/country-field';
import BooleanField from '@/shared/fields/boolean-field';
import ComputedField from '@/shared/fields/computed-field';
import { BeneficiaryField } from '@/modules/document-series-allocation/fields/beneficiary-field';
import DateField from '@/shared/fields/date-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    user_type_name: new StringField('user_type_name', label('user_type_name'), { required: true }), //st-filter-input has to be updated with radio option and a new type of field created
    personal_numeric_code: new StringField('personal_numeric_code', label('personal_numeric_code'), { personalNumericCode: true }),
    passport_number: new StringField('passport_number', label('passport_number'), { required: true }),
    first_name: new StringField('first_name', label('first_name'), { required: true }),
    last_name: new StringField('last_name', label('last_name'), { required: true }),
    id_card_serial: new StringField('id_card_serial', label('id_card_serial'), { required: true }),
    id_card_number: new StringField('id_card_number', label('id_card_number'), { required: true }),
    id_card_expiration_date: new DateField('id_card_expiration_date', label('id_card_expiration_date'), { required: true }),
    id_card_issuer: new StringField('id_card_issuer', label('id_card_issuer'), { required: true }),    
    email: new EmailField('email', label('email'), { required: true }),
    phone_number: new StringField('phone_number', label('phone'), { required: true, phoneNumber: true }),
    company_name: new StringField('company_name', label('company_name'), { required: true }),
    iban: new StringField('iban', label('iban'), { required: true, iban: true }),
    bank: new StringField('bank', label('bank'), { required: true }),
    license: new StringField('license', label('license'), { }),
    unique_registration_code: new StringField('unique_registration_code', label('unique_registration_code'), { uniqueRegistrationCode: true }),
    registration_number: new StringField('registration_number', label('registration_number')),
    country: CountryField.relationToOne(
        'country',
        label('country'),
        { autocompleteRequired: true },
        { placeholder: placeholder('country')},
    ),
    city: new StringField('city', label('city'), { required: true }),
    address: new StringField('address', label('address'), { required: true }),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true , firstLetterFilter: true},
        { placeholder: placeholder('county')},
        [],
        { asyncData: true }
    ),
    county: new StringField('county', label('county')),
    locality_id: LocalityField.relationToOne(
        'locality_id',
        label('locality'),
        { autocompleteRequired: true, firstLetterFilter: true },
        { placeholder: placeholder('locality')},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: true }
    ),
    locality: new StringField('locality', label('locality')),
    village_id: VillageField.relationToOne(
        'village_id',
        label('village'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true },
        { placeholder: placeholder('village')},
        [{
            key: 'parent_id',
            name: 'locality_id'
        }],
        { asyncData: true }
    ),
    village: new StringField('village', label('village')),
    sector: new StringField('sector', label('sector')),
    street: new StringField('street', label('street'), { required: true }),
    street_number: new StringField('street_number', label('street_number'), { required: true }),
    postal_code: new StringField('postal_code', label('postal_code')),
    staircase: new StringField('staircase', label('staircase')),
    block: new StringField('block', label('block')),
    floor: new StringField('floor', label('floor')),
    apartment: new StringField('apartment', label('apartment')),
    use_profile_data: new BooleanField('use_profile_data', label('use_profile_data')),
    full_name: new ComputedField('full_name', label('full_name'),
        (record) => {
            if (!record) return '';
            const { first_name, last_name } = record;
            return `${first_name} ${last_name}`;
        },
        { placeholder: placeholder('full_name') }
    ),
    user_type: new ComputedField('user_type', label('user_type'),
        (record) => {
            if (!record) return '';

            if (record.user_type_name === 'legal_entity') {
                return i18n.t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL');
            } else {
                return i18n.t('BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL');
            }
        },
    ),
    beneficiary: BeneficiaryField.relationToOne('beneficiaries', label('beneficiary'),
    { autocompleteRequired: false, enabled: false, firstLetterFilter: true },
    { placeholder: placeholder('beneficiary')})
};

export class BeneficiaryModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
