<template>
    <div class="form-group" id="st_beneficiary_section">
        <BeneficiaryFormTypes
            ref="beneficiary"
            :viewMode="viewMode"
            :item="selectedEntity"
            @submit="submit"
            @cancel="cancel"
        >
            <template #buttons="{ submit, cancel }">
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="cancel">
                        {{ $t('APPLICATION.MODALS.CANCEL') }}
                    </st-button>
                    <st-button size="large" :callback="submit">
                          {{ submitButtonText }}
                    </st-button>
                </div>
            </template>
        </BeneficiaryFormTypes>
    </div>
</template>

<script>
import BeneficiaryFormTypes from "./BeneficiaryFormTypes";
import { mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: "BeneficiaryForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
        },
    },
    data() {
        return {
            selectedEntity: null
        };
    },
    components: {
        BeneficiaryFormTypes,
    },
    computed: {
        ...mapGetters({
            record: 'documentSeriesAllocation/form/record',
        }),
        submitButtonText() {
            return this.item ? this.$t('APPLICATION.MODALS.SAVE') : this.$t('APPLICATION.MODALS.ADD');
        },
    },
    methods: {
        onModelChange(model) {
            this.$emit("change", model);
        },
        async validateData() {
            const fieldRefs = this.$refs.beneficiary.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key]
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            let status = await selectedEntity.fv.validate();
            let details = this.fillBeneficiaryModel(selectedEntity);

            const beneficiary_id = this.item?.beneficiary_id || generateRandomId();
            const beneficiaryDetails = { ...selectedEntity.model, ...details, beneficiary_id };
            const isPersonalNumericCodeUsed = this.checkUniquePersonalNumericCode(this.items, beneficiaryDetails);
            if (isPersonalNumericCodeUsed) {
                    this.$alert({
                    type: 'warning',
                        text: this.$t('APPLICATION.NOTIFIERS.DUPLICATE_BENEFICIARY_PNC', {pnc: beneficiaryDetails.personal_numeric_code}),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                    });
                    return { status: 'Invalid'};
            }

            return {
                status,
                beneficiaryDetails,
            };
        },
        fillBeneficiaryModel(entity) {
            if (entity.model.user_type_name === "foreigner") {
                return {};
            }

            let details = {
                locality: entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
                village: entity.fields.village_id?.options.find((el) => el.value === entity.model.village_id)?.text || "",
            };

            return details;
        },
        async submit() {
            const beneficiary = await this.validateData();

            if (beneficiary.status === 'Valid') {
                if (this.item) {
                    beneficiary.index = this.item.index;
                }

                this.$emit("submitForm", beneficiary);
            }
        },
        cancel() {
            this.$emit("cancelForm");
        },
        checkUniquePersonalNumericCode(beneficiaries, beneficiaryDetails) {
            return beneficiaries
                .filter(el => el.beneficiary_id !== beneficiaryDetails.beneficiary_id)
                .some((el) => el.personal_numeric_code === beneficiaryDetails.personal_numeric_code);
        }
    },
    created() {
        this.selectedEntity = this.item || null;
    },
};
</script>
