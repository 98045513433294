<template>
    <st-section
        :header="$t('BENEFICIARY.LIST_HEADER')"
        customClass="list-section"
        id="beneficiary-target"
        :validate="!viewMode"
        :wasValidated="wasValidated"
        :validation="validationStatus"
    >
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="308"
                :space="300"
                :count="itemsComputed.length"
                dispay="3"
                ref="beneficiaryCarousel"
            >
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card beneficiary-card">
                        <beneficiary-item-card
                            v-if="!item.isPlaceholder"
                            @edit="onEdit"
                            @delete="onDelete"
                            :item="item"
                            :index="i"
                            :viewMode="viewMode"
                        ></beneficiary-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-user"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex list-footer">
                <div class="col-10 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
                <div
                    class="col-2 d-flex justify-content-end px-0"
                    v-if="!viewMode"
                >
                    <st-button :callback="openBeneficiaryModal" variant="link">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.ADD_BTN") }}
                        </span>
                    </st-button>
                </div>
            </div>

            <beneficiary-modal
                ref="beneficiaryModal"
                :item="selectedEntity"
                :items="items"
                @processBeneficiary="processBeneficiary"
                @cancel="cancel"
            ></beneficiary-modal>
        </template>
    </st-section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { BeneficiaryModel } from "@/modules/document-series-allocation/models/beneficiary-model";
import BeneficiaryModal from "@/modules/document-series-allocation/components/beneficiary/BeneficiaryModal";
import BeneficiaryItemCard from "@/modules/document-series-allocation/components/beneficiary/BeneficiaryItemCard.vue";

const { fields } = BeneficiaryModel;

export default {
    name: "BeneficiaryList",
    components: {
        BeneficiaryModal,
        BeneficiaryItemCard,
        Carousel3d,
        Slide,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        setDefault: {
            type: Boolean,
            default: false,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
        };
    },
    watch: {
        record(newValue) {
            this.items = newValue?.beneficiary_users ?? [];

            this.$emit("changeList", this.items, "beneficiary_users");
        },
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            record: 'documentSeriesAllocation/form/record',
        }),
        fields() {
            fields.full_name.tdClass = "d-flex col-md-3 align-items-center";
            fields.user_type.tdClass = "d-flex col-md-7 align-items-center";
            return [fields.full_name, fields.user_type];
        },
        total() {
            return this.items.length === 1
                ? this.$t("BENEFICIARY.SINGLE", { total: 1 })
                : this.$t("BENEFICIARY.MULTIPLE", { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items?.length;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for (let i = 0; i < placeholderItems; i += 1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
        validationStatus() {
            return this.items?.length ? "success" : "error";
        },
    },
    methods: {
        openBeneficiaryModal() {
            this.$refs.beneficiaryModal.show();
        },
        processBeneficiary(data) {
            const isEdit = Object.keys(data).some((el) => el === "index");
            if (isEdit) {
                Vue.set(this.items, data.index, data.beneficiaryDetails);
            } else {
                this.items.push({
                    ...data.beneficiaryDetails,
                    index: this.items.length,
                });
                this.goToSlide(this.items.length - 1);
            }

            this.$emit("changeList", this.items, "beneficiary_users");
            this.selectedEntity = null;
        },
        onEdit({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.selectedEntityIndex = index;
            this.$refs.beneficiaryModal.show();
        },
        onDelete({ index }) {
            this.items.splice(index, 1);
            this.goToSlide(0);
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        goToSlide(index) {
            this.$refs.beneficiaryCarousel.goSlide(index);
        },
        computeItems() {
            return this.record?.beneficiary_users ?? [];
        }
    },
    created() {
        this.items = [];

        if (this.setDefault) {
            this.items = this.computeItems();
        }
    },
};
</script>
