<template>
    <div>
        <real-estate-land-form
            ref="realEstateLand"
            :item="item"
            :viewMode="viewMode"
        >
        </real-estate-land-form>
        <div class="d-flex justify-content-between mt-6">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("APPLICATION.MODALS.CANCEL") }}
            </st-button>
            <st-button size="large" :callback="submit">
                {{ $t("APPLICATION.MODALS.ADD") }}
            </st-button>
        </div>
    </div>
</template>

<script>
import { RealEstateModel } from "@/modules/document-series-allocation/models/real-estate-model";
import { FormSchema } from "@/shared/form/form-schema";
import { mapGetters } from "vuex";
import RealEstateLandForm from "@/modules/document-series-allocation/components/real-estate/RealEstateLandForm";
import Message from "@/shared/message/message";

const { fields } = RealEstateModel;

const formSchema = new FormSchema([fields.real_estate_type]);

export default {
    name: "RealEstateForm",
    components: {
        RealEstateLandForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
        cadastralNumber: {
            type: Array
        }
    },
    data() {
        return {
            fields,
            model: null,
            selectedType: null,
            selectedEntity: null
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: 'documentSeriesAllocation/form/record',
            parcels: 'documentSeriesAllocation/form/parcels',
        }),
    },
    methods: {
        async validateRealEstate() {
            const fieldRefs = this.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key] && fieldRefs[key].fields
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            const status = await selectedEntity.fv.validate();

            if (selectedEntity.model.county_id !== this.defaultCountyId) {
                const county = selectedEntity.fields.county_id.options.find((el) => el.value === this.defaultCountyId)?.text || "";
                Message.error('REAL_ESTATE.FORM.WRONG_COUNTY', { county });
                return;
            }

            let details = this.fillRealEstateDetails(selectedEntity);

            const realEstateDetails = { ...selectedEntity.model, ...details };

            if (selectedEntity?.files) {
                realEstateDetails.identification_files = selectedEntity.files.filter((el) => el.file_id);
                realEstateDetails.files = selectedEntity.files.filter((el) => !el.hasOwnProperty('file_id'));
            }

            return {
                status,
                realEstateDetails,
            };
        },
        fillRealEstateDetails(entity) {
            let details = {
                territorial_administrative_unit: entity.fields.territorial_administrative_unit_id.options.find((el) => el.value === entity.model.territorial_administrative_unit_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
                real_estate_type: this.selectedType,
            };

            if (this.selectedType === "land_constructions") {
                details.village = entity.fields.village_id.options.find((el) => el.value === entity.model.village_id)?.text || "";
                details.locality = entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "";
            }

            return details;
        },
        cancel() {
            this.$emit('cancelForm');
        },
        async submit() {
            const realEstate = await this.validateRealEstate();
            const parcels = this.parcels?.find((el) => el.topo_number === realEstate.realEstateDetails.cadastral_number);

            if (realEstate.status === 'Valid') {
                let isUnique = true;
                if (this.item) {
                    realEstate.index = this.item.index;
                    isUnique = !this.cadastralNumber.some((el) => (
                        el.cadastral_number === realEstate.realEstateDetails.cadastral_number &&
                        el.territorial_administrative_unit === realEstate.realEstateDetails.territorial_administrative_unit &&
                        el.index !== this.item.index
                    ));
                } else {
                    isUnique = !this.cadastralNumber.some((el) => (
                        el.cadastral_number === realEstate.realEstateDetails.cadastral_number &&
                        el.territorial_administrative_unit === realEstate.realEstateDetails.territorial_administrative_unit
                    ));
                }

                //check if area is the same
                if (window.VUE_APP_ENV === 'production'
                    && parseInt(parcels?.area) !== parseInt(realEstate.realEstateDetails.land_area)) {
                    Message.error('REAL_ESTATE.FORM.DIFFERENT_AREA');
                    return;
                }

                //check if cadastral number is unique
                if (isUnique) {
                    this.$emit("submitForm", realEstate);
                } else {
                    Message.error('REAL_ESTATE.FORM.DUPLICATE_CADASTRAL_NUMBER');
                }
            }
        },
    },
    created() {
        if ((this.viewMode || this.record?.status === "draft")) {
            this.model = formSchema.initialValues(
                this.record.real_estate_target
            );
        } else {
            if (this.item) {
                this.model = formSchema.initialValues(this.item);
            } else {
                const record = {
                    real_estate_type: "land",
                    county_id: this.defaultCountyId,
                };

                this.model = formSchema.initialValues(record);
            }
        }
    },
    mounted() {
        this.selectedEntity = this.item ? this.item : null;

        this.selectedType = "land";
    },
};
</script>
